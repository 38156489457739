<template>
	<ViewLayout>
		<template #header-title>
			{{ 'Metadata Keys' | useLabels }}
		</template>
		<template #header-caption>
			Add, edit and manage {{ 'metadata Keys' | useLabels }}
		</template>
		<template #content>
			<FilterBar
				:search-query.sync="searchQuery"
				search-query-label="Metadata Key Name"
				hide-brand-filter
				@clearFilters="clearFilters"
			>
				<template #after-filters>
					<Dropdown
						v-model="selectedComponentTypeId"
						label="Cassie Component Type"
						custom-sort
						:items="cassieComponentTypeFilterItems"
					/>
				</template>
				<template #action>
					<PrimaryActionButton
						v-if="!readOnlyPermissions"
						@click="showAddModal = true"
					>
						<v-icon left>
							mdi-plus
						</v-icon>
						Create
					</PrimaryActionButton>
				</template>
			</FilterBar>
			<SectionCard>
				<template #title>
					{{ 'Metadata Keys' | useLabels }}
				</template>
				<template #body>
					<DataTable
						:headers="tableHeaders"
						:items="filteredExternalMetaData"
						sort-by="id"
						sort-desc
						@click:row="rowClick"
					>
						<template #item.action="{ item }">
							<IconButton
								tooltip-text="Open Metadata Key"
								@click="externalMetaDataToEdit = item"
							>
								{{ readOnlyPermissions ? 'mdi-eye' : 'mdi-pencil' }}
							</IconButton>
							<IconButton
								v-if="!readOnlyPermissions"
								tooltip-text="Delete Metadata Key"
								@click.stop.prevent="externalMetaDataToRemove = item"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
					<ConfirmDeleteModal
						v-if="externalMetaDataToRemove"
						:entity-name="externalMetaDataToRemove.name"
						entity-type="Metadata Key"
						@close="externalMetaDataToRemove = null"
						@delete="deleteExternalMetaData"
					/>
					<ManageExternalMetaDataModal
						v-if="showAddModal || externalMetaDataToEdit"
						:external-meta-data-to-edit="externalMetaDataToEdit"
						:cassie-component-types="cassieComponentTypeItems"
						@close="closeManageExternalMetaDataModal"
						@submit="loadExternalMetaData"
					/>
				</template>
			</SectionCard>
		</template>
	</ViewLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import ManageExternalMetaDataModal from './manage-meta-data-keys-modal.vue'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import { deleteExternalMetaData, getExternalMetaData } from '../../../../../shared/utils/api/external-meta-data.js'
import { META_DATA_KEYS_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'

export default {
	components: { PrimaryActionButton, FilterBar, DataTable, ConfirmDeleteModal, Dropdown, ManageExternalMetaDataModal, SectionCard, ViewLayout, IconButton },
	data () {
		return {
			searchQuery: '',
			selectedComponentTypeId: null,
			showFilters: false,
			externalMetaDataToEdit: null,
			externalMetaDataToRemove: null,
			externalMetaDataItems: [],
			cassieComponentTypes: [],
			tableHeaders: [
				{
					value: 'id',
					text: 'ID',
					width: '10%'
				},
				{
					value: 'name',
					text: useLabels('Metadata Key Name'),
					width: '20%'
				},
				{
					value: 'componentTypeNames',
					text: 'Component Types',
					width: '60%'
				},
				{
					value: 'action',
					text: 'Action',
					width: '10%',
					sortable: false
				}
			],
			showAddModal: false
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		filteredExternalMetaData () {
			return this.externalMetaDataItems.filter(({ name, associatedComponentTypes }) => {
				let check = true
				if (this.searchQuery) check = name.toLowerCase().includes(this.searchQuery.toLowerCase())
				if (this.selectedComponentTypeId !== null) check = check && associatedComponentTypes.includes(this.selectedComponentTypeId)
				return check
			}).map(metaData => ({
				...metaData,
				componentTypeNames: metaData.associatedComponentTypes.map(typeId => this.cassieComponentTypeItems.find(({ value }) => typeId === value)?.text).filter(name => name).join(', ')
			}))
		},
		readOnlyPermissions () {
			return !this.checkPermission(META_DATA_KEYS_MODULE_FULL_PERMISSIONS)
		},
		cassieComponentTypeItems () {
			return this.cassieComponentTypes.map(({ componentName, componentTypeId }) => ({
				text: componentName,
				value: componentTypeId
			})).sort((a, b) => a.text.localeCompare(b.text))
		},
		cassieComponentTypeFilterItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				...this.cassieComponentTypeItems
			]
		}
	},
	created () {
		this.loadExternalMetaData()
	},
	methods: {
		closeManageExternalMetaDataModal () {
			this.externalMetaDataToEdit = null
			this.showAddModal = false
		},
		rowClick (row) {
			this.externalMetaDataToEdit = row
		},
		async deleteExternalMetaData () {
			await deleteExternalMetaData(this.externalMetaDataToRemove.id)
			showSnackbar('You have removed this metadata key')
			this.externalMetaDataToRemove = null
			this.loadExternalMetaData()
		},
		async loadExternalMetaData () {
			const { externalMetaData, cassieComponentTypes } = await getExternalMetaData()
			this.externalMetaDataItems = externalMetaData
			this.cassieComponentTypes = cassieComponentTypes
		},
		clearFilters () {
			this.selectedComponentTypeId = null
		}
	}
}
</script>
